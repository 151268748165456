import React from "react";

import { Link } from "react-router-dom";
import nodevinWordmark from "../../assets/img/brand-icons/wordmark.png"

export default function Footer() {
  return (
    <>
      <hr className="border-black w-full" />
      <footer className="relative pt-8 pb-6">
        <div className="container mx-auto px-4">
          <div className="flex font-inconsolata lg:text-center lg:text-left">
            <div className="w-full sm:w-4/12 px-4">
              <div className="">
                <div
                  className="lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none block"
                  id="example-navbar-warning"
                >
                  <div className="flex flex-col items-center">
                    <Link
                      to="/"
                      className="text-sm font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase"
                    >
                      <img
                        className="footer-wordmark mx-auto self-center my-2"
                        src={nodevinWordmark}
                        alt="nodevin"
                      />
                    </Link>

                    <ul className="flex lg:flex-row list-none">
                      <li className="flex items-center">
                        <a
                          className="text-black px-2 py-4 lg:py-2 flex items-center uppercase font-bold"
                          href="https://discord.com/invite/XuhW2ykW3D"
                          target="_blank"
                        >
                          <i className="text-black fab fa-discord footer-icon leading-lg " />
                        </a>
                        <a
                          className="text-black px-2 py-4 lg:py-2 flex items-center text-lg uppercase font-bold"
                          href="https://x.com/node_vin"
                          target="_blank"
                        >
                          <i className="text-black fab fa-twitter footer-icon leading-lg " />
                        </a>
                        <a
                          className="text-black px-2 py-4 lg:py-2 flex items-center uppercase font-bold"
                          href="https://github.com/fiftysixcrypto/nodevin"
                          target="_blank"
                        >
                          <i className="text-black fab fa-github footer-icon leading-lg " />
                        </a>
                        <a
                          className="text-black px-2 py-4 lg:py-2 flex items-center uppercase font-bold"
                          href="/#/download"
                        >
                          <i className="text-black fas fa-regular fa-download footer-icon leading-lg " />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full sm:w-4/12 px-4">
              <div className="flex flex-nowrap items-top mb-6">
                <div className="lg:w-3/12 px-4 lg:ml-auto">
                  <span className="block uppercase text-sm font-semibold mb-2">
                    Socials
                  </span>
                  <ul className="footer-list list-disc">
                    <li>
                      <a
                        className="font-semibold block pb-2 text-sm"
                        href="https://x.com/node_vin"
                      >
                        X
                      </a>
                    </li>
                    <li>
                      <a
                        className="font-semibold block pb-2 text-sm"
                        href="https://www.github.com/fiftysixcrypto/nodevin"
                      >
                        Github
                      </a>
                    </li>
                    <li>
                      <a
                        className="font-semibold block pb-2 text-sm"
                        href="https://discord.com/invite/XuhW2ykW3D"
                      >
                        Discord
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <span className="block uppercase text-sm font-semibold mb-2">
                    Other
                  </span>
                  <ul className="footer-list list-disc">
                    <li>
                      <a
                        className="font-semibold block pb-2 text-sm"
                        href="/#/blog"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        className="font-semibold block pb-2 text-sm"
                        href="https://hub.docker.com/u/fiftysix"
                      >
                        Dockerhub
                      </a>
                    </li>
                    <li>
                      <a
                        className="font-semibold block pb-2 text-sm"
                        href="mailto:business@nodevin.xyz"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="font-inconsolata text-sm font-semibold py-1">
                Copyright © {new Date().getFullYear()} Nodevin by{" "}
                <a
                  href="https://www.fiftysix.xyz"
                  className="text-blueGray-500 hover:text-blueGray-800"
                >
                  Fiftysix
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
