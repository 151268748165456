import React from "react";
import { Link } from "react-router-dom";

import consoleAnimationOne from "../assets/img/nodevin-start-video.gif"
import consoleAnimationTwo from "../assets/img/nodevin-info-video.gif"
import consoleAnimationThree from "../assets/img/nodevin-view-video.gif"
import asciiGlobe from "../assets/img/ascii_earth.svg"

import graphXIcon from "../assets/img/chart-icons/x.png"
import graphCheckIcon from "../assets/img/chart-icons/y.png"

import nodevinWordmark from "../assets/img/brand-icons/wordmark.png"

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative md:pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto flex flex-wrap items-center md:h-full">

          {/* Left Section (Placeholder for Image or Content) */}
          <div className="w-full sm:w-6/12 flex items-center justify-center h-full">
            <div className="max-h-860px">
              <img
                src={asciiGlobe}
                alt="Ascii Globe"
                className="rounded-md max-h-full"
              />
            </div>
          </div>

          {/* Right Section (Text Content) */}
          <div className="w-full pt-8 sm:pt-0 sm:w-6/12 px-6 flex items-center h-full">
            <div className="text-center sm:text-left">
              <h2 className="font-helvetica landing-title">
                Your Software, One Command
              </h2>
              <p className="mt-4 font-inconsolata landing-subtext leading-relaxed">
                Nodevin is an open source CLI tool that empowers global participation in decentralization.
              </p>
              <div className="mt-12 get-started-button-wrapper">
                <a
                  href="/#/download"
                  className="font-inconsolata get-started-button text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-black uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Download Now
                </a>
                <a
                  href="https://github.com/fiftysixcrypto/nodevin"
                  className="font-inconsolata get-started-button ml-1 text-gray font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-100 active:bg-gray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  target="_blank"
                >
                  View Github
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-black mx-32" />

      <section className="header relative items-center flex explanatory-viewpoints max-h-860-px">
        <div className="container mx-auto flex flex-wrap items-center">
          <div className="w-full text-center md:text-left md:w-3/12 lg:w-3/12 px-4 lg:mr-auto">
            <div className="pt-64 sm:pt-0">
              <h2 className="font-helvetica landing-other-title">
                Deploy Nodes with Ease
              </h2>
              <p className="font-inconsolata mt-4 landing-other-subtext leading-relaxed">
                Automate blockchain software setup and maintenance with Docker containerization.
              </p>
            </div>
          </div>

          <div className="w-full md:w-8/12 lg:w-8/12 flex items-center justify-center lg:ml-auto terminal-animation">
            <div className="bg-white border border-gray-700 rounded-lg p-4">
              <img
                src={consoleAnimationOne}
                alt="Animated Demonstration"
                className="rounded-md max-h-full"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="header relative items-center flex explanatory-viewpoints max-h-860-px">
        <div className="container mx-auto flex flex-wrap items-center">
          <div className="w-full text-center md:text-left md:w-3/12 lg:w-3/12 px-4 lg:mr-auto">
            <div className="pt-64 sm:pt-0">
              <h2 className="font-helvetica landing-other-title">
                Harness Real-Time Insights
              </h2>
              <p className="font-inconsolata mt-4 landing-other-subtext leading-relaxed">
                Monitor node performance, track uptime, and view important stats to ensure network connectivity.
              </p>
            </div>
          </div>

          <div className="w-full md:w-8/12 lg:w-8/12 flex items-center justify-center lg:ml-auto terminal-animation">
            <div className="bg-white border border-gray-700 rounded-lg p-4">
              <img
                src={consoleAnimationTwo}
                alt="Animated Demonstration"
                className="rounded-md max-h-full"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="header relative items-center flex explanatory-viewpoints max-h-860-px">
        <div className="container mx-auto flex flex-wrap items-center">
          <div className="w-full text-center md:text-left md:w-3/12 lg:w-3/12 px-4 lg:mr-auto">
            <div className="pt-64 sm:pt-0">
              <h2 className="font-helvetica landing-other-title">
                Level Up Your Experience
              </h2>
              <p className="font-inconsolata mt-4 landing-other-subtext leading-relaxed">
                Access personalized analytics that evolve alongside your uptime and connectivity.
              </p>
            </div>
          </div>

          <div className="w-full md:w-8/12 lg:w-8/12 flex items-center justify-center lg:ml-auto terminal-animation">
            <div className="bg-white border border-gray-700 rounded-lg p-4">
              <img
                src={consoleAnimationThree}
                alt="Animated Demonstration"
                className="rounded-md max-h-full"
              />
            </div>
          </div>
        </div>
      </section>

      <hr className="border-black mx-32" />

      <section className="header relative items-center flex max-h-860-px justify-center">
        <div className="text-center flex flex-col items-center mt-24 mb-48">
          <div className="w-full pt-64 px-12 md:px-4">
            <h2 className="font-helvetica landing-other-title">Decentralize On Your Terms</h2>
            <p className="font-inconsolata mt-4 landing-other-subtext leading-relaxed">
              Blockchain accessibility made cheaper and easier
            </p>
            <br />

            <table className="nodevin-table w-full text-left">
              <thead>
                <tr className="font-helvetica">
                  <th></th>
                  <th className="text-center">
                    <img className="chart-wordmark mx-auto" src={nodevinWordmark} alt="nodevin" />
                  </th>
                  <th>Other Guys</th>
                  <th>DIY</th>
                </tr>
              </thead>

              <tbody>
                {[
                  ["Multiple blockchain support", "✔", "✔", "✔"],
                  ["Easy setup", "✔", "✔", "✔"],
                  ["Full control over data", "✔", "✘", "✔"],
                  ["Customizable security", "✔", "✘", "✔"],
                  ["Fully open-source", "✔", "✘", "✔"],
                  ["Automated updates", "✔", "✘", "✘"],
                  ["Cross-platform support", "✔", "✘", "✘"],
                  ["Universal data snapshots", "✔", "✘", "✘"],
                  ["Open-source Docker images", "✔", "✘", "✘"],
                ].map(([feature, nodevin, provider, personalNode], index) => (
                  <tr key={index} className="font-helvetica">
                    <td style={{ textAlign: "left", fontWeight: 400 }}>{feature}</td>
                    <td className="text-center">
                      <img className="mx-auto" src={nodevin === "✔" ? graphCheckIcon : graphXIcon} alt={nodevin === "✔" ? "Check" : "X"} />
                    </td>
                    <td className="text-center">
                      <img className="mx-auto" src={provider === "✔" ? graphCheckIcon : graphXIcon} alt={provider === "✔" ? "Check" : "X"} />
                    </td>
                    <td className="text-center">
                      <img className="mx-auto" src={personalNode === "✔" ? graphCheckIcon : graphXIcon} alt={personalNode === "✔" ? "Check" : "X"} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className="header relative items-center flex max-h-860-px justify-center">
        <div className="text-center flex flex-col items-center mt-24 mb-48">
          <div className="w-full pt-64 px-12 md:px-4">
            <h2 className="font-helvetica landing-other-title">Join the Decentralized Revolution</h2>
            <p className="font-inconsolata landing-other-subtext leading-relaxed mt-4 mb-8">
              Start supporting your favorite networks today
            </p>
            <a
              href="/#/download"
              className="font-inconsolata get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-black uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              Download Nodevin
            </a>
          </div>
        </div>
      </section>

      <section className="mb-24 relative">
        <div className="w-full px-4">
          <div className="flex flex-wrap justify-center">
            {/* Blog Post Cards */}
            {[
              {
                title: "How Nodevin Works",
                date: "November 22, 2024",
                excerpt: "Insight into the technical components powering Nodevin's functionality.",
                category: "Technology",
                link: "/#/blog/How%20Nodevin%20Works",
              },
              {
                title: "What We Stand For",
                date: "October 11, 2024",
                excerpt: "Short points that describe what Nodevin stands for.",
                category: "Vision",
                link: "/#/blog/What%20We%20Stand%20For",
              },
              {
                title: "Why Decentralization Requires Accessibility",
                date: "October 1, 2024",
                excerpt: "True decentralization is achieved with accessible tools.",
                category: "Other",
                link: "/#/blog/Why%20Decentralization%20Requires%20Accessibility",
              },
              {
                title: "Inspiration Behind Nodevin",
                date: "July 15, 2024",
                excerpt: "Answering questions about the origin and inspiration for Nodevin.",
                category: "Other",
                link: "/#/blog/Inspiration%20Behind%20Nodevin",
              },
            ].map((post, index) => (
              <div key={index} className="w-full md:w-5/12 lg:w-3/12 mb-8">
                <div className="relative flex flex-col min-w-0 break-words bg-white border border-gray-300 rounded-lg shadow-md h-full">
                  <a href={post.link} target="_blank" className="blog-card px-6 py-5 flex-auto">
                    {/* Category Oval */}
                    <div className={"post-category text-white text-xs font-semibold px-3 py-1 rounded-full inline-block mb-3"}>
                      {post.category}
                    </div>

                    {/* Title and Excerpt */}
                    <h6 className="card-front font-inconsolata text-xl font-semibold mb-2">
                      {post.title}
                    </h6>
                    <p className="card-back font-inconsolata mb-4">
                      {post.excerpt}
                    </p>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap justify-center">
            <a
              href="/#/blog"
              target="_blank"
              className="font-inconsolata get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-black uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              Read More
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
